import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Firebase configuration for the default app
const firebaseConfig = {
  apiKey: "AIzaSyDTNbs1Clv4gt15PzL08EwIyw0o3pGkN5s",
  authDomain: "bianat-56ef3.firebaseapp.com",
  projectId: "bianat-56ef3",
  storageBucket: "bianat-56ef3.appspot.com",
  messagingSenderId: "1069718418185",
  appId: "1:1069718418185:web:7a2d800fea0a160fb219d8",
};

// Firebase configuration for the chatbot app
const firebaseConfigChatBot = {
  apiKey: "AIzaSyB1jK2npyv9hm0ZVYaVkS1__wr77ZY2EtA",
  authDomain: "bianatchatbot.firebaseapp.com",
  projectId: "bianatchatbot",
  storageBucket: "bianatchatbot.appspot.com",
  messagingSenderId: "891224431048",
  appId: "1:891224431048:web:8309dfc804cb8b0ef408fd",
  measurementId: "G-0STPKEFVGZ",
};

// Initialize Firebase apps
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const appChatBot = getApps().find(app => app.name === "chatbot") || initializeApp(firebaseConfigChatBot, "chatbot");

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const authChatBot = getAuth(appChatBot);
export const dbChatBot = getFirestore(appChatBot);
export const storageChatBot = getStorage(appChatBot);
