import React from "react";
import * as ReactDOM from "react-dom";
import i18next from "./utils/i18next";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import "./index.css";
import { IAuthProvider } from "./context/iAuthContext";

ReactDOM.render(
  <React.StrictMode>
    <IAuthProvider>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </IAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
