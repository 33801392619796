import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Content } from "antd/lib/layout/layout";
import BianatHeader from "../../../components/BianatHeader";
import HighLights from "../../home/components/Highlights";
import { collection, getDocs, query, where } from "firebase/firestore";
import { dbChatBot } from "../../../utils/firebase/config";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";


const ChatThread = ({chatId}) => {
  // Essentials
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const currentTheme = useSelector((state) => state.currentTheme.currentTheme);
  const navigate = useNavigate();
  const params = useParams();
  // const { chatId } = params;

  const [messages, setMessages] = useState(null);
  const [loading , setLoading] = useState(false)

  // FUNCTIONS
  const fetchData = async () => {
    setLoading(true)
    setMessages(null)
    try {
      // Reference to the 'messages' collection inside the specific chat document
      const messagesCollectionRef = collection(dbChatBot, `allChats/${chatId}/messages`);
      
      // Fetch all documents from the 'messages' collection
      const querySnapshot = await getDocs(messagesCollectionRef);
      
      // Map through the documents and store them in state
      const messagesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(messages)
      setMessages(messagesData);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching chat messages: ", error);
      setLoading(false)
    }
  };
  

  useEffect(() => {
    if(chatId){
      fetchData();
    }
  }, [chatId]);

  // RETURNING UI
  return (
    <div className="h-full overflow-scroll">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-3">
           {
            messages &&
            messages.map((item,index)=>(
              <div key={index} className={` ${index%2===0?"self-end":""} flex flex-col gap-1 w-fit bg-[#004e8654] px-3 py-1 rounded-lg`}>
                <p className="font-bold">{index%2===0?"Prompt:":"Answer:"}</p>
                <p>{item.content}</p>
              </div>
            ))
           }
           {!messages && !loading && 
           <div>
            <p>Select a chat</p>
           </div>
           }
           
          </div>
        </div>
        {
            loading && 
            <div className="w-full h-full flex justify-center items-center">
            <Spin />
            </div>
           }
    </div>
  );
}

export default ChatThread
