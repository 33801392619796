import { IAuthContext } from "../context/iAuthContext";
import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { authChatBot, dbChatBot } from "../utils/firebase/config";
import { Spin } from "antd";

const IsLoggedIn = ({ Component }) => {
  const { iAuth, iSub, updateIAuth } = useContext(IAuthContext);
  const [loading, setLoading] = useState(true);

  const updateUserDoc = async (userId) => {
    const userCollectionRef = doc(dbChatBot, "users", userId); // Access the document directly
    try {
      await updateDoc(userCollectionRef, { subscription: "free" }); // Await the update
      console.log("User subscription updated to 'free'");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };  

  useEffect(() => {
    const checkUserStatus = async () => {
      const unsubscribe = onAuthStateChanged(authChatBot, async (user) => {
        if (user) {
          const uid = user.uid;
          const userCollectionRef = collection(dbChatBot, "users");
          const userQuery = query(userCollectionRef, where("id", "==", uid));
          const querySnapshot = await getDocs(userQuery);

          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            
            const currentDate = new Date();
            const expirationDate = userData?.expiryDate?.toDate()
            if (currentDate > expirationDate) {
              console.log("Payment has expired");
              updateIAuth({ auth: true, sub: "free", userId: userData.id });
              updateUserDoc(userData.id)
              setLoading(false);
            } else {
              // console.log("Payment is valid");
              console.log(userData)
              updateIAuth({
                auth: true,
                sub: userData.subscription,
                userId: userData.id,
              });
              setLoading(false);
            }
          } else {
            updateIAuth({ auth: false, sub: null, userId: "" });
            setLoading(false);
          }
        } else {
          updateIAuth({ auth: false, sub: null, userId: "" });
          setLoading(false);
        }
      });

      return () => unsubscribe();
    };

    checkUserStatus();
  }, [iAuth, iSub, loading]);

  if (loading) {
    return (
      <Spin
        size="large"
        style={{
          justifyContent: "center",
          marginTop: "25%",
          display: "flex",
        }}
      />
    );
  }


  return <Component />;
};

export default IsLoggedIn;
