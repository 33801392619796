import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Content } from "antd/lib/layout/layout";
import BianatHeader from "../../../components/BianatHeader";
import HighLights from "../../home/components/Highlights";
import { collection, getDocs, query, where } from "firebase/firestore";
import { dbChatBot } from "../../../utils/firebase/config";
import { useNavigate } from "react-router-dom";
import ChatThread from "../chatThread/ChatThread";

const UserChatHistory = () => {
  // Essentials
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const currentTheme = useSelector((state) => state.currentTheme.currentTheme);
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [userHistory, setUserHistory] = useState(null);
  const [chatToShow, setChatToShow] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  // USE EFFECT
  useEffect(() => {
    const user = localStorage.getItem("iAuth");
    if (user) {
      const parsedUser = JSON.parse(user);
      if (parsedUser.auth) {
        if (parsedUser.sub === "free" || parsedUser.sub === null) {
          navigate("/payment");
        }
      }else{
        navigate("/i-login")
      }
    }
  }, []);

  // FUNCTIONS
  const fetchData = async () => {
    try {
      const ref = collection(dbChatBot, "allChats");
      const q = query(ref, where("userId", "==", id));
      const response = await getDocs(q);
      const data = response.docs.map((doc) => doc.data());
      // console.log(data);
      setUserHistory(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // HANDLES

  const handleSearch = (e) => {
    // let value = e.target.value
    // if(value.trim() === ""){
    //   return
    // }

    // let filteredValues = userHistory.filter(item =>
    //   (item.chatTitle + item.chatSubTitle).toLowerCase().includes(value.toLowerCase())
    // );

    // console.log(filteredValues.length  , filteredValues)

    setSearchValue(e.target.value.trim());
  };

  // TEMP VARIABLE
  let filteredValues = userHistory?.filter((item) =>
    (item.chatTitle + item.chatSubTitle)
      .toLowerCase()
      .includes(searchValue.toLowerCase())
  );

  // RETURNING UI
  return (
    <div className="h-full">
      <BianatHeader setIsGuideOpen={setIsGuideOpen} followUpPage={"true"} />
      <Content
        className={`landing-content  ${
          i18n.language === "en" ? "font-loader-en" : "font-loader"
        } ${currentTheme === "Dark" && "dark-skin"}`}
      >
        <div className="live-update-toolbar">
          <HighLights />
        </div>
        <div className="px-8 py-3 flex flex-col gap-3">
          <h1 className="text-[30px]">{t("adminUploadData.chatHistory")}</h1>
          <div className="flex ">
            <div className="flex-1 min-w-[200px] max-h-[90vh] h-[90vh] min-h-[90vh] flex flex-col py-8 px-4 bg-[#202c3c7a] ">
              <div className="font-bold text-[24px] mb-2">
                <h1>Bianat Chat</h1>
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  placeholder="Search Here..."
                  onChange={(e) => handleSearch(e)}
                  className="bg-slate-800 rounded-lg py-2 px-1 outline-none w-full"
                />
              </div>
              <div className="overflow-y-scroll  flex-1">
                {filteredValues &&
                  filteredValues.map((item) => (
                    <div
                      key={item.chatId}
                      className="cursor-pointer hover:bg-[#222] p-1"
                      onClick={() => setChatToShow(item.chatId)}
                    >
                      <p>
                        {item.chatTitle
                          .split(" ")
                          .slice(0, 4)
                          .map((word) => word + " ")}{" "}
                        ...
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex-[4]  max-h-[90vh] h-[90vh] min-h-[90vh] px-4 py-8 overflow-y-scroll bg-[#202c3cd2]">
              <ChatThread chatId={chatToShow} />
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default UserChatHistory;
