import React, { createContext, useState, useEffect } from "react";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { authChatBot, dbChatBot } from "../utils/firebase/config";
import { signOut } from "firebase/auth";

export const IAuthContext = createContext();

export const IAuthProvider = ({ children }) => {
  const [iAuth, setIAuth] = useState(false);
  const [iSub, setISubscription] = useState(null);
  const [user_Id, setUser_Id] = useState("");
  const [paymentId, setPaymentId] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state


  const updateIAuth = (authData) => {
    const { auth, sub, userId, paymentId } = authData;
    console.log(auth,userId , sub)
    if (paymentId) {
      setPaymentId(paymentId);
    }
    setIAuth(auth);
    setISubscription(sub);
    setUser_Id(userId);
    localStorage.setItem(
      "iAuth",
      JSON.stringify({ auth, sub, userId })
    );
    setLoading(false);
  };

  return (
    <IAuthContext.Provider
      value={{
        iAuth,
        iSub,
        user_Id,
        paymentData,
        updateIAuth,
        loading,
      }}
    >
      {children}
    </IAuthContext.Provider>
  );
};
