import React, { useContext, useEffect, useState } from "react";
import authApi from "../../../services/authApi";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FadeInFromDown } from "@shared/lib/utils";
import AnimatedText from "@shared/ui/AnimatedText";
import { cn } from "@shared/lib/utils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IAuthContext } from "../../../context/iAuthContext";
import Navbar from "../../landing/Components/Navbar"
import LanguageModal from "../../landing/Components/LanguageModal";
import Footer from "../../landing/Components/Footer";
import MoyasarPay from "./MoyassarPay";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { dbChatBot } from "../../../utils/firebase/config";
import { showToast } from "@shared/utilites/toast";

const SubPayment = () => {
  // const {user_Id} = useContext(IAuthContext)
  const navigate = useNavigate();
  const [isOpen , setIsOpen] = useState(false)
  const [plans, setPlans] = useState(null)
  const [chosePlan , setChosePlan] = useState(null)
  const { t, i18n } = useTranslation();

  const {iAuth , iSub , user_Id} = useContext(IAuthContext)

  useEffect(()=>{
    console.log("IAUTH CONTEXT : " , iAuth , iSub , user_Id )
    const userLS = localStorage.getItem("iAuth")
    if(userLS){
      const parsedUser = JSON.parse(userLS)
      console.log("LOCAL STORAGE I AUTH : " , parsedUser)
    }
  } , [iAuth , iSub])


  useEffect(() => {
    const fetchData = async ()=>{
      try {
        const docRef = collection(dbChatBot , "subscriptions")
        const q = query(docRef, orderBy("price")); 
const docsSnap = await getDocs(q);
        const data = docsSnap.docs.map(doc => doc.data())
        console.log(data)
        if(data){
          setPlans(data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, []);

  const handlePayment = async (plan) => {
    if(!iAuth){
      showToast("Please Login First" , "error")
      return
    }
    if(iSub === "paid"){
      showToast("You have already subscribed" , "error")
      return
    }
    setChosePlan(plan)
  };

  return (
    <>
      <div className="px-14 font-cairo">

      <Navbar onLangPress={()=>setIsOpen(true)} />
      </div>
      <LanguageModal
                    isOpen={isOpen}
                    closeModal={() => setIsOpen(false)}
                />
    <div className="px-14 pt-14 font-cairo">
      
      { !chosePlan && <div
        id="plans"
        className="w-full flex flex-col items-center "
      >
        <AnimatedText delay={0.6} className="text-3xl">
          <>
            {t("CHOOSE YOUR BIANAT PLAN")
              .split(" ")
              .map((word, index) => (
                <span
                  key={index}
                  className={`inline-block mx-1 ${
                    index == 1 && "text-blue-600"
                  }`}
                >
                  {word}
                </span>
              ))}
          </>
        </AnimatedText>
        <AnimatedText delay={0.8} className="mt-5 text-black text-opacity-80">
          {t("Cancel Any Time").toString()}
        </AnimatedText>
        <div className="mt-12 grid grid-cols-1 md:grid-cols-4">
          {plans && plans.map((plan, index) => (
            <motion.div
              variants={FadeInFromDown}
              initial="hidden"
              whileInView="visible"
              custom={{ delay: index * 0.2 }}
              viewport={{ once: true }}
              key={index}
              className={cn(
                "text-center bg-blue-600 relative m-2 p-2 rounded-md flex flex-col justify-between text-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]",
                {
                  ["outline-dashed outline-blue-600 shadow-xl shadow-blue-600 z-10"]:
                    plan.titleEn === "ANNUAL",
                }
              )}
            >
              {plan.titleEn == "ANNUAL" && (
                <h1 className="absolute -left-8 bg-red-500 p-2 rounded-md z-10 text-white top-0 -rotate-45">
                  {t("Best Value").toString()}
                </h1>
              )}
              <h1 className="text-lg text-white mb-4 min-w-fit">
                {t(`subscription_plans.${plan.titleEn}.title`).toString()}
              </h1>

              <p className="">
                {t(`subscription_plans.${plan.titleEn}.description`).toString()}
              </p>
              <div className="mt-2 text-white/70">
                <p>
                  {t(`subscription_plans.${plan.titleEn}.discount`).toString()}
                </p>
                <p>
                  {t(`subscription_plans.${plan.titleEn}.subtitle`).toString()}
                </p>
              </div>
              <button
                onClick={() => handlePayment(plan)}
                className="text-blue-600 w-full rounded-md py-2 mt-4 bg-white active:scale-95 transition-all duration-150 ease-in-out"
              >
                {t("Subscribe").toString()} - {plan.price}{" "}
                {t("Sr").toString()}
              </button>
              {t(`subscription_plans.${plan.plan_code}.info`) && (
                <div className="mt-2">
                  {t(`subscription_plans.${plan.name}.info`).toString()}
                </div>
              )}
            </motion.div>
          ))}
        </div>
      </div>}
    <div className="pt-14">
      {
        chosePlan && 
       <MoyasarPay userChosePlan={chosePlan?chosePlan:null} />
      }
    <Footer/>
    </div>
    </div>
    </>
  );
};

export default SubPayment;
