import React, { useEffect } from "react";
import { dbChatBot } from "../../../utils/firebase/config";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { motion } from "framer-motion";
const MoyasarPay = ({ userChosePlan }) => {
  useEffect(() => {
    let userId = "";
    const user = localStorage.getItem("iAuth");
    if (user) {
      const parsedUser = JSON.parse(user);
      userId = parsedUser.userId;
    }
    if (userChosePlan && userId) {
      console.log(userChosePlan, userId);
      Moyasar.init({
        element: ".mysr-form",
        amount: userChosePlan.price * 100,
        currency: "SAR",
        description: `${userChosePlan.titleEn} subscription bought.`,
        publishable_api_key: "pk_test_Z2zVRUUZyj7bPuudrEX4FBTACBhr5SCogW7ckePw",
        callback_url: "http://dev.bianat.sa/thanks",
        methods: ["creditcard"],
        on_completed: async function (payment) {
          let nPayment = { ...payment };
          console.log(nPayment);
          const expiryDate = new Date();
          expiryDate.setDate(expiryDate.getDate() + userChosePlan.duration);
          const docRef = doc(collection(dbChatBot, "payments"));

          const paymentData = {
            id: docRef.id,
            payment: nPayment,
            expiryDate,
            paymentDate: new Date(),
            userId,
            subscriptionPlan: userChosePlan,
          };

          await setDoc(docRef, paymentData);

          console.log("New payment document created with ID:", docRef.id);

          // Assuming `userDocRef` is the reference to the user document
          const userDocRef = doc(collection(dbChatBot, "users"), userId);

          // Define the fields you want to update in the user document
          const updatedUserData = {
            subscription: "paid",
            subscriptionPlanId: userChosePlan.id,
            paymentId: docRef.id,
            expiryDate,
          };

          // Update the document with the specified fields
          await updateDoc(userDocRef, updatedUserData);

          console.log("User document updated successfully!");
        },
      });
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5  }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="mysr-form"
    ></motion.div>
  );
};

export default MoyasarPay;
