import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Navigate, useLocation } from "react-router-dom";
import { KuzzleContext } from "../App";
import {
  getSubscriptionDetails,
  updateSubscription,
} from "../features/Auth/authSlice";

const PrivateRoute = ({ Component }) => {
  const dispatch = useDispatch();
  const { kuzzleSocket: kuzzle } = useContext(KuzzleContext);
  const auth = useSelector((state) => state.auth);
  const [room, setRoom] = useState(null);
  const loc = useLocation();

  useEffect(() => {
    if (auth.isAuth) {
      (async () => {
        const filters = {
          equals: {
            username: auth?.user?.username,
          },
        };

        const roomId = await kuzzle.realtime.subscribe(
          "bianat",
          "users",
          filters,
          (notification) => {
            const { result } = notification;

            if (result._source?.subscription) {
              // Only update if the subscription has changed
              if (auth.user?.subscription !== result._source.subscription) {
                dispatch(updateSubscription(result._source?.subscription));
                dispatch(getSubscriptionDetails());
              }
            }
          }
        );

        setRoom(roomId);
      })();

      return async () => {
        if (room) {
          await kuzzle.realtime.unsubscribe(room);
        }
      };
    }
  }, [auth.isAuth]);

  // CHANGES DONE HERE
  if (!auth.isAuth) {
    return <Navigate to="/" replace={true} />;
  }

  if (auth.user?.subscription === "free") {
    if (
      loc.pathname === "/dashboard" ||
      loc.pathname === "/settings" ||
      loc.pathname === "/followUp" ||
      loc.pathname === "/openPostions" ||
      loc.pathname === "/marketPulse" ||
      loc.pathname === "/treemap" ||
      loc.pathname === "/console" ||
      loc.pathname === "/screener"
    ) {
      return <Component />;
    }
    return <Navigate to="/dashboard" replace />;
  }

  return <Component />;
};

export default PrivateRoute;
