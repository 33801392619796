import React, { useState } from "react";

const RiskCalculator = () => {
  // State variables for input fields
  const [calculatorType, setCalculatorType] = useState("Dollar");
  const [portfolioSize, setPortfolioSize] = useState(100000);
  const [sharePrice, setSharePrice] = useState(50);
  const [stopPrice, setStopPrice] = useState(45);
  const [amountToRisk, setAmountToRisk] = useState(1000);
  const [annualRiskPercentage, setAnnualRiskPercentage] = useState(5);

  // State variable for storing results
  const [results, setResults] = useState(null);

  // Function to calculate risk
  function calculateRisk(
    calculatorType,
    portfolioSize,
    sharePrice,
    stopPrice,
    amountToRisk,
    annualRiskPercentage,
    targetMultiples = [1, 2, 3, 4, 5, 6]
  ) {
    const stopAmount = sharePrice - stopPrice; // Difference between share price and stop price
    const riskPerShare = stopAmount; // Risk per share
    const numberOfSharesToBuy = amountToRisk / riskPerShare; // Number of shares to buy
    const amtPosition = numberOfSharesToBuy * sharePrice; // Total amount of the position
    const positionSizePercent = (amtPosition / portfolioSize) * 100; // Position size as a percentage of portfolio
    const riskAsPercentOfEquity = (amountToRisk / portfolioSize) * 100; // Risk as a percentage of equity

    // Calculate upside target prices based on multiples of risk
    const gainTargets = targetMultiples.map((r) => ({
      multiple: r,
      targetPrice: sharePrice + r * stopAmount,
      percentGain: ((r * stopAmount) / sharePrice) * 100,
    }));

    return {
      amtPosition: amtPosition.toFixed(2),
      numberOfSharesToBuy: Math.floor(numberOfSharesToBuy),
      stopAmount: stopAmount.toFixed(2),
      positionSizePercent: positionSizePercent.toFixed(2),
      riskAsPercentOfEquity: riskAsPercentOfEquity.toFixed(2),
      gainTargets: gainTargets,
    };
  }

  // Handler function for the calculate button
  const handleCalculate = () => {
    const result = calculateRisk(
      calculatorType,
      portfolioSize,
      sharePrice,
      stopPrice,
      amountToRisk,
      annualRiskPercentage
    );
    setResults(result); // Store results in state
  };

  return (
    <div className="p-[50px]">
      <div className="max-w-md mx-auto p-6 border rounded-lg shadow-lg bg-white">
        <h2 className="text-2xl font-bold mb-4">Risk Calculator</h2>

        <div className="mb-4">
          <label className="block text-lg font-medium mb-1">
            Calculator Type:
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded-md"
            value={calculatorType}
            onChange={(e) => setCalculatorType(e.target.value)}
          >
            <option value="Dollar">Dollar</option>
            <option value="Percent">Percent</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-lg font-medium mb-1">
            Portfolio Size:
          </label>
          <input
            className="w-full p-2 border border-gray-300 rounded-md"
            type="number"
            value={portfolioSize}
            onChange={(e) => setPortfolioSize(Number(e.target.value))}
          />
        </div>

        <div className="mb-4">
          <label className="block text-lg font-medium mb-1">Share Price:</label>
          <input
            className="w-full p-2 border border-gray-300 rounded-md"
            type="number"
            value={sharePrice}
            onChange={(e) => setSharePrice(Number(e.target.value))}
          />
        </div>

        <div className="mb-4">
          <label className="block text-lg font-medium mb-1">Stop Price:</label>
          <input
            className="w-full p-2 border border-gray-300 rounded-md"
            type="number"
            value={stopPrice}
            onChange={(e) => setStopPrice(Number(e.target.value))}
          />
        </div>

        <div className="mb-4">
          <label className="block text-lg font-medium mb-1">
            Amount to Risk:
          </label>
          <input
            className="w-full p-2 border border-gray-300 rounded-md"
            type="number"
            value={amountToRisk}
            onChange={(e) => setAmountToRisk(Number(e.target.value))}
          />
        </div>

        <div className="mb-4">
          <label className="block text-lg font-medium mb-1">
            Annual Risk Percentage:
          </label>
          <input
            className="w-full p-2 border border-gray-300 rounded-md"
            type="number"
            value={annualRiskPercentage}
            onChange={(e) => setAnnualRiskPercentage(Number(e.target.value))}
          />
        </div>

        <button
          onClick={handleCalculate}
          className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition"
        >
          Calculate Risk
        </button>
      </div>

      <div class="p-4">
        {results && (
          <div>
            <h3 class="text-xl font-semibold mb-4">Results</h3>
            <table class="min-w-full bg-white border border-gray-200 rounded-lg shadow-md mb-8">
              <thead class="bg-gray-100">
                <tr>
                  <th class="py-2 px-4 border-b text-left text-gray-700">
                    \$ Amt Position
                  </th>
                  <th class="py-2 px-4 border-b text-left text-gray-700">
                    # of Shares to Buy
                  </th>
                  <th class="py-2 px-4 border-b text-left text-gray-700">
                    \$ Stop Amount
                  </th>
                  <th class="py-2 px-4 border-b text-left text-gray-700">
                    Position Size %
                  </th>
                  <th class="py-2 px-4 border-b text-left text-gray-700">
                    Risk as % of Equity
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="hover:bg-gray-50">
                  <td class="py-2 px-4 border-b">{results.amtPosition}</td>
                  <td class="py-2 px-4 border-b">
                    {results.numberOfSharesToBuy}
                  </td>
                  <td class="py-2 px-4 border-b">{results.stopAmount}</td>
                  <td class="py-2 px-4 border-b">
                    {results.positionSizePercent}
                  </td>
                  <td class="py-2 px-4 border-b">
                    {results.riskAsPercentOfEquity}
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 class="text-xl font-semibold mb-4">Gain Targets</h3>
            <table class="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
              <thead class="bg-gray-100">
                <tr>
                  <th class="py-2 px-4 border-b text-left text-gray-700">
                    Multiple of Risk
                  </th>
                  <th class="py-2 px-4 border-b text-left text-gray-700">
                    Target Price
                  </th>
                  <th class="py-2 px-4 border-b text-left text-gray-700">
                    % Gain
                  </th>
                </tr>
              </thead>
              <tbody>
                {results.gainTargets.map((target, index) => (
                  <tr key={index} class="hover:bg-gray-50">
                    <td class="py-2 px-4 border-b">{target.multiple}R</td>
                    <td class="py-2 px-4 border-b">
                      {target.targetPrice.toFixed(2)}
                    </td>
                    <td class="py-2 px-4 border-b">
                      {target.percentGain.toFixed(2)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskCalculator;
