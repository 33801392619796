import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Content } from "antd/lib/layout/layout";
import BianatHeader from "../../components/BianatHeader";
import HighLights from "../home/components/Highlights";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase/config";

const OpenPostions = () => {
  // Essentials
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const currentTheme = useSelector((state) => state.currentTheme.currentTheme);
  const [tableData , setTableData] = useState(null)

  const fetchData = async ()=>{
    try {
      const response =await getDocs(collection(db, "openPositions"))
      const data = response.docs.map((doc) => doc.data());
      console.log(data)
      setTableData(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  // USE EFFECT
  useEffect(() => {
    const user = localStorage.getItem("iAuth");
    if (user) {
      const parsedUser = JSON.parse(user);
      if (parsedUser.auth) {
        if (parsedUser.sub === "free" || parsedUser.sub === null) {
          navigate("/payment");
        }
      }else{
        navigate("/i-login")
      }
    }
  }, []);


  // STYLES
  const thStyles = "border-r border-gray-600"

  // RETURNING UI
  return (
    <div className="h-full">
      <BianatHeader setIsGuideOpen={setIsGuideOpen} followUpPage={"true"} />
      <Content
        className={`landing-content min-h-[100vh] ${
          i18n.language === "en" ? "font-loader-en" : "font-loader"
        } ${currentTheme === "Dark" && "dark-skin"}`}
      >
        <div className="live-update-toolbar">
          <HighLights />
        </div>
        <div className="px-8 py-8 flex flex-col gap-8">
          <h1 className="text-[30px]">{t("openPosition.heading")}</h1>
          <h1 className="text-[20px]">{t("openPosition.subheading")}</h1>
          <div className="border-1 border-white pb-20 max-md:overflow-y-scroll">
            <table className="min-w-full divide-y divide-gray-600 border-[1px] border-[#222]">
              <thead className="" style={{border:currentTheme === "Dark"?"1px solid white":"#222"}}>
                <tr style={{backgroundColor:currentTheme==="Dark"?"#283142":"#f0f4fc"}}>
                  <th className={thStyles} style={{padding:"10px"}} >{t("openPosition.action")}</th>
                  <th className={thStyles} style={{padding:"10px"}} >{t("openPosition.P&L")}(%)</th>
                  <th className={thStyles} style={{padding:"10px"}} >{t("openPosition.time")}</th>
                  <th className={thStyles} style={{padding:"10px"}} >Est. EPS Date</th>
                  <th className={thStyles} style={{padding:"10px"}} >Avg. Vol(k)</th>
                  <th className={thStyles} style={{padding:"10px"}} >{t("openPosition.stopLoss")}(%)</th>
                  <th className={thStyles} style={{padding:"10px"}} >{t("openPosition.stopLoss")}</th>
                  <th className={thStyles} style={{padding:"10px"}} >{t("openPosition.BuyPrice")}</th>
                  <th className={thStyles} style={{padding:"10px"}} >{t("openPosition.BuyDate")}</th>
                  <th  className={thStyles} style={{padding:"10px"}}>{t("openPosition.symbol")}</th>
                </tr>
              </thead>
              <tbody className=" divide-y divide-gray-600">
                { tableData && 
                tableData.map((row, index) => (
                  <tr key={index} style={{backgroundColor:currentTheme === "Dark"? index%2 === 0?"#20242c":"#283142":"#fff"}}>
                    <td className={thStyles} style={{padding:"20px 10px"}}>{row.actions}</td>
                    <td className={thStyles} style={{padding:"20px 10px"}}>{row.PL}</td>
                    <td className={thStyles} style={{padding:"20px 10px"}}>{row.time}</td>
                    <td className={thStyles} style={{padding:"20px 10px"}}>
                      {row.ESPDate}
                    </td>
                    <td className={thStyles} style={{padding:"20px 10px"}}>{row.avgVol}</td>
                    <td className={thStyles} style={{padding:"20px 10px"}}>{row.stopLossPercent}</td>
                    <td className={thStyles} style={{padding:"20px 10px"}}>
                      {row.stopLoss}
                    </td>
                    <td className={thStyles} style={{padding:"20px 10px"}}>{row.buyPrice}</td>
                    <td className={thStyles} style={{padding:"20px 10px"}}>{row.buyDate}</td>
                    <td className={thStyles} style={{padding:"20px 10px"}}>{row.symbol}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default OpenPostions;
