import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Content } from "antd/lib/layout/layout";
import BianatHeader from "../../components/BianatHeader";
import HighLights from "../home/components/Highlights";
import { collection, getDocs } from "firebase/firestore";
import { dbChatBot } from "../../utils/firebase/config";
import { useNavigate } from "react-router-dom";

const ChatBotHistory = () => {
  // Essentials
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const currentTheme = useSelector((state) => state.currentTheme.currentTheme);
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);

  // USE EFFECT
  useEffect(() => {
    const user = localStorage.getItem("iAuth");
    if (user) {
      const parsedUser = JSON.parse(user);
      if (parsedUser.auth) {
        if (parsedUser.sub === "free" || parsedUser.sub === null) {
          navigate("/payment");
        }
      }else{
        navigate("/i-login")
      }
    }
  }, []);

  // FUNCTIONS
  const fetchData = async () => {
    try {
      const response = await getDocs(collection(dbChatBot, "users"));
      const data = response.docs.map((doc) => doc.data());
      data.forEach((item) => {
        console.log(item.createdDate);
      });
      setUserData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const thStyles = "border-r border-gray-600";

  // RETURNING UI
  return (
    <div className="h-full">
      <BianatHeader setIsGuideOpen={setIsGuideOpen} followUpPage={"true"} />
      <Content
        className={`landing-content min-h-[100vh] ${
          i18n.language === "en" ? "font-loader-en" : "font-loader"
        } ${currentTheme === "Dark" && "dark-skin"}`}
      >
        <div className="live-update-toolbar">
          <HighLights />
        </div>
        <div className="px-8 py-8 flex flex-col gap-8">
          <h1 className="text-[30px]">{t("adminUploadData.chatHistory")}</h1>
          <div>
            <table
              className="table-fixed min-w-full divide-y divide-gray-600 border-[1px] border-[#222]"
              style={{ tableLayout: "fixed !important", width: "100%" }}
            >
              <thead
                className=""
                style={{
                  border: currentTheme === "Dark" ? "1px solid white" : "#222",
                }}
              >
                <tr
                  style={{
                    backgroundColor:
                      currentTheme === "Dark" ? "#283142" : "#f0f4fc",
                  }}
                >
                  <th className={thStyles} style={{ padding: "10px" }}>
                    {t("chatHistory.name")}
                  </th>
                  <th className={thStyles} style={{ padding: "10px" }}>
                    {t("chatHistory.email")}
                  </th>
                  <th className={thStyles} style={{ padding: "10px" }}>
                    {t("chatHistory.phone")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {userData &&
                  userData.map((row, index) => (
                    <tr
                      onClick={() => navigate(`/chat-bot-history/${row.id}`)}
                      key={index}
                      style={{
                        cursor:"pointer",
                        backgroundColor:
                          currentTheme === "Dark"
                            ? index % 2 === 0
                              ? "#20242c"
                              : "#283142"
                            : "#fff",
                      }}
                    >
                      <td className={thStyles} style={{ padding: "20px 10px" }}>
                        {row.name}
                      </td>
                      <td className={thStyles} style={{ padding: "20px 10px" }}>
                        {row.email ? row.email : "N/A"}
                      </td>
                      <td className={thStyles} style={{ padding: "20px 10px" }}>
                        {row.phone?.number
                          ? row.phone.dialCode + " " + row.phone.number
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default ChatBotHistory;
