import { useContext, useState , useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { authChatBot, dbChatBot } from "../../../utils/firebase/config";
import { IAuthContext } from "../../../context/iAuthContext";
import { showToast } from "@shared/utilites/toast";
import { HashLoader } from "react-spinners";
import { signIn } from "@shared/api/auth";
import { signIn as SignInRedux } from "../../../features/Auth/authSlice";
import { useDispatch } from "react-redux";
import { BiGlobe } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";

const InitialLogin = () => {
  const navigate = useNavigate();
  const { iAuth, iSub, updateIAuth, userId, fetchPaymentData } = useContext(IAuthContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  
  // HANDLE FUNCTION
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Attempt to sign in with email and password
      const userCredential = await signInWithEmailAndPassword(authChatBot, email, password);

      // Get the logged-in user's UID
      const uid = userCredential.user.uid;

      // FETCH DATA
      const userDocRef = collection(dbChatBot, "users");
      const q = query(userDocRef, where("id", "==", uid));
      const userDataRes = await getDocs(q);

      const [userData, ...restOfArr] = userDataRes.docs.map((item) => item.data());
      if (userData) {
        let authData = {
          subscriptionDetails: {
            type: "paid",
          },
          user: userData,
          access_token: "20b11f418df679d38cce732d23515181ee2befc7f62642471f196a503b2db3fa",
        };

        const signInResponse = await signIn({ email, password });
        // if (signInResponse.code === "error") {
        //   showToast(t(signInResponse.error.response?.data.message), "error");
        // }
        if (signInResponse.code === "success") {
          console.log("SUCESSSSSSSSSSSSSSSSSSS");
          dispatch(SignInRedux(signInResponse.data));
        }

        navigate("/");
      } else {
        showToast("User Data Not Found!", "error");
      }
      setLoading(false);
    } catch (error) {
      if (error.message.includes("auth/invalid-credential")) {
        showToast("Invalid Credentials!", "error");
      } else {
        showToast("Oops! Error Occured!", "error");
      }
      console.log(error);
      setLoading(false);
    }
  };

  // -------------------------------------------------

  const [showMenu, setShowMenu] = useState(false);

  const { t, i18n } = useTranslation();

  const handleEnglish = () => {
    i18n.changeLanguage("en");
  };

  const handleArabic = () => {
    i18n.changeLanguage("ar");
  };

  return (
    <>
      {/* ===============>>> Navbar */}
      <div className=" font-cairo absolute z-[999] flex justify-between items-center px-14 py-8 font-space w-full padding-x">
        <Link to="/" className="cursor-pointer w-[130px] h-[46px] max-md:w-[100px] max-md:h-[60px]">
          <img src="/images/companyLogo.png" alt="logo" className="w-full h-full" />
        </Link>

        <div className="relative">
          <div
            className="flex items-center gap-2 text-[#878787]"
            onClick={(e) => {
              e.preventDefault();
              setShowMenu(!showMenu);
            }}
            onMouseEnter={(e) => {
              e.preventDefault();
              setShowMenu(true);
              return;
            }}
            onMouseLeave={(e) => {
              e.preventDefault();
              setShowMenu(false);
              return;
            }}
          >
            <BiGlobe className="w-6 h-6" />
            <h2 className="text-[18px] font-[700] text-[#878787]">Language</h2>
            <FaChevronDown
              strokeWidth={3}
              className={`transition-all duration-300 ${showMenu && "transform rotate-180"}`}
            />
          </div>

          {/* language menu */}
          {showMenu && (
            <div
              className="absolute w-[120px] bg-primary border-[3px] border-bgWhite top-[100%] rounded-lg py-2 px-1 text-[18px] font-[700] tracking-wide"
              onMouseEnter={(e) => {
                e.preventDefault();
                setShowMenu(true);
                return;
              }}
              onMouseLeave={(e) => {
                e.preventDefault();
                setShowMenu(false);
                return;
              }}
            >
              <div className="flex flex-col items-center gap-2 cursor-pointer">
                <h3
                  onClick={handleEnglish}
                  className="text-textWhite hover:text-primary hover:bg-bgGray hover:rounded-md w-full text-center active:text-textGray transition-all"
                >
                  English
                </h3>
                <h3
                  onClick={handleArabic}
                  className="text-textWhite hover:text-primary hover:bg-bgGray hover:rounded-md w-full text-center active:text-textGray transition-all"
                >
                  Arabic
                </h3>
              </div>
            </div>
          )}
          {/* ------------- */}
        </div>
      </div>

      <div className="flex items-center gap-10 min-h-screen">
        {/* ===============>>> Left Side <<<================ */}
        <div
          className={` font-cairo w-[50%] h-full top-0 fixed bg-[#F7F8FA] max-lg:hidden ${
            i18n.language === "ar" ? "right-0" : "left-0"
          }`}
        >
          <div className="px-12 absolute right-0 w-full h-full top-[50%] -translate-y-1/2 flex items-center justify-center gap-10 max-xl:flex-col">
            <div className="flex flex-col">
              <h2 className="text-[24px] font-[500] leading-[34px]">{t("additional.loginParaH")}</h2>
              <p>
                {t("additional.loginPara")}
              </p>
            </div>
            <img src="/images/loginImg.png" alt="" />
          </div>

          <div className="absolute bottom-0 right-1/2 translate-x-1/2">
            <div className="flex items-center justify-between gap-20 bg-white py-6 px-4 rounded-2xl">
              {["terms", "privacy", "Docs", "Helps"].map((item, index) => (
                <div key={index}>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* ===============>>> Right Side <<<================ */}
        <div
          className={`font-cairo w-[50%] font-[400] max-lg:w-full ${
            i18n.language === "ar" ? "mr-auto" : "ml-auto"
          }`}
        >
          <div className="relative">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              {loading && <HashLoader size="42" color={"#2563eb"} />}
            </div>
            <div className="w-full px-16 max-sm:px-5">
              <h1 className="text-[32px] font-[600]">{t("additional.loginWithBianat")}</h1>
              <form className="mt-8 flex flex-col gap-3">
                <h3 className="text-[16px] font-[500]">{t("additional.email")}</h3>
                {/* =============>>> Email <<<============== */}
                <div className="px-4 py-[15px] bg-[#F8F8F8] rounded-[8.4px]">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full bg-transparent outline-none"
                  />
                </div>
                <h3 className="mt-3 flex justify-between text-[16px] font-[500]">
                {t("additional.passwords")}<button className="text-pri"></button>
                </h3>

                {/* =============>>> Password <<<============== */}
                <div className="px-4 py-[15px] bg-[#F8F8F8] rounded-[8.4px]">
                  <input
                    type="password"
                    id="password"
                    value={password}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full bg-transparent outline-none"
                  />
                </div>

                <button
                  className="mt-8 w-full text-white bg-pri py-[15px] rounded-[8.4px]"
                  onClick={handleLogin}
                >
                  Log In
                </button>
              </form>

              <div className="mt-8 w-full flex items-center justify-center">
                <p className="font-[400] text-[14.7px]">
                {t("additional.dontHaveAcc")}{" "}
                  <span className="text-pri" onClick={() => navigate("/i-signup")}>
                  {t("additional.signup")}
                  </span>
                </p>
              </div>

              <div className="fixed bottom-0 right-1/2 translate-x-1/2">
                <div className="hidden max-lg:flex items-center justify-between gap-20 bg-white py-6 px-4 rounded-2xl max-sm:gap-10">
                  {["terms", "privacy", "Docs", "Helps"].map((item, index) => (
                    <div key={index}>
                      <p>{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InitialLogin;
